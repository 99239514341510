import { ROUTES } from '../core/helpers/routes';
import { vaultService } from '../core/services/vault-service';
import PanelBayer from '../pages/Bayer/PanelBayer';
import Statistic from '../pages/Bayer/Statistic';
import Domeins from '../pages/Domeins';
import PanelFarmer from '../pages/Farmer/PanelFarmer';
import ShowWhitePage from '../pages/Farmer/ShowWhitePage';
import ListUsers from '../pages/SuperAdmin/ListUsers';
import HelperSysAdmin from '../pages/SysAdmin/HelperSysAdmin';
import PanelSysAdmin from '../pages/SysAdmin/PanelSysAdmin';
import ListBuyer from '../pages/WebMaster/ListBuyer';
import ListDomainBuyer from '../pages/WebMaster/ListDomainBuyer';
import PanelWebMaster from '../pages/WebMaster/PanelWebMaster';

export const sysadminRole = [
    { link: ROUTES.sysAdminPanel.link, component: <PanelSysAdmin /> },
    { link: ROUTES.domeins.path, component: <Domeins /> },
];

export const helperSysadminRole = [{ link: ROUTES.helperSysAdminPanel.link, component: <HelperSysAdmin /> }];

export const farmerRole = [
    { link: ROUTES.panelFarmer.link, component: <PanelFarmer /> },
    { link: ROUTES.showWhitePage.link, component: <ShowWhitePage /> },
];

export const bayerRole = [
    { link: ROUTES.panelBayer.link, component: <PanelBayer /> },
    { link: ROUTES.showWhitePage.link, component: <ShowWhitePage /> },
    { link: ROUTES.statistic.link, component: <Statistic /> },
];

export const webmasterRole = [
    { link: ROUTES.listBuyer.link, component: <PanelWebMaster /> },
    { link: ROUTES.listDomainBuyer.link, component: <ListDomainBuyer /> },
    { link: ROUTES.showWhitePage.link, component: <ShowWhitePage /> },
    { link: ROUTES.statistic.link, component: <Statistic /> },
];

export const adminRole = [...sysadminRole, ...helperSysadminRole, ...farmerRole, ...bayerRole];

export const superAdminRole = [{ link: ROUTES.listUsers.link, component: <ListUsers /> }];

export const renderRoutesWithRole = () => {
    const service = vaultService();
    const role = service.getItem('role');

    switch (role) {
        case 'sysadmin':
            return sysadminRole;
        case 'helper':
            return helperSysadminRole;
        case 'farmer':
            return farmerRole;
        case 'buyer':
            return bayerRole;
        case 'admin':
            return adminRole;
        case 'webmaster':
            return webmasterRole;
        case 'superadmin':
            return superAdminRole;
        default:
            return adminRole;
    }
};

export const loginRole = (role) => {
    switch (role) {
        case 'sysadmin':
            return 'sysadmin';
        case 'helper':
            return 'helper';
        case 'farmer':
            return 'farmer';
        case 'buyer':
            return 'buyer';
        case 'admin-123456789':
            return 'admin';
        case 'webmaster':
            return 'webmaster';
        case 'superadmin':
            return 'superadmin';
        default:
            return 'error';
    }
};
