import { Col, Row } from 'react-bootstrap';
import './styles.scss';
import Button from '../../../components/UI/Button';
import { useDispatch, useSelector } from 'react-redux';
import { changePages, getDomeinsBuyer, getDomeinsBuyerReserved } from '../actions';
import { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { changeStatusDomainWarm } from '../../Farmer/actions';
import { vaultService } from '../../../core/services/vault-service';
import UIFormInput from '../../../components/UI/UIFormInput';

const ListDomain = ({ showStatusDomain }) => {
    const { domains, page } = useSelector((state) => state.buyerReducer);
    const [isLoading, setIsLoading] = useState(null);
    const [name, setName] = useState('');
    const timeoutRef = useRef(null);

    const dispatch = useDispatch();

    const loadData = () => {
        dispatch(getDomeinsBuyer(page, name));
    };

    const service = vaultService();
    const { id } = service.getItem('user');

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePages(currentPage));
        await dispatch(getDomeinsBuyer(currentPage, name));
    };

    const handleClickButton = async (item) => {
        if (isLoading !== null) return;
        setIsLoading(item.id);
        const options = {
            topic_id: item['topic'].id,
            name: item.name,
            registrar: item.registrar,
            domain_ip: item.domain_ip,
            domain_ip_alt: item.domain_ip_alt,
            server_id: item.server.id,
            status: 'reserved',
            domain_data: item.domain_data ?? [],
            user_id: id,
            type: item.type,
        };
        const resp = await dispatch(changeStatusDomainWarm(options, item.id));
        if (resp) {
            loadData();
        }
        setIsLoading(null);
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleChangeInput = (e) => {
        const { value } = e.target;
        setName(value);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(async () => {
            await dispatch(getDomeinsBuyer(page, value));
        }, 300);
    };

    return (
        <div className="ListDomain">
            <div className="d-flex flex-column">
                <Row className="border-bottom pb-3 text-bold position-relative">
                    <Col className="ListDomain-item_header">Name</Col>
                    <Col className="ListDomain-item_header">Topic</Col>
                    <Col className="ListDomain-item_header">GEO</Col>
                    <Col className="ListDomain-item_header">Status (прогрева РК)</Col>
                    <Col className="ListDomain-item_header"></Col>
                    <div className="MyDomain-search">
                        <UIFormInput
                            onChange={handleChangeInput}
                            type="text"
                            id="name"
                            placeholder="Search domain"
                            value={name}
                        />
                    </div>
                </Row>
                {domains.data.map((item) => (
                    <Row className={`border-bottom pb-3 pt-4 d-flex`} key={item.id}>
                        <Col className="ListDomain-item">
                            {showStatusDomain(item)}
                            {item.name}
                        </Col>
                        <Col className="ListDomain-item">{item['topic'].topic}</Col>
                        <Col className="ListDomain-item">{item.server.geolocation}</Col>
                        <Col className="ListDomain-item">{item.status}</Col>
                        <Col className="ListDomain-item" style={{ maxWidth: '210px', marginLeft: 'auto' }}>
                            <Button
                                onClick={() => handleClickButton(item)}
                                blue
                                isLoading={isLoading === item.id}
                                title="Зарезервивовать"
                            />
                        </Col>
                    </Row>
                ))}
            </div>
            <div className="pagination">
                {domains.meta?.total > domains.meta?.per_page && (
                    <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={domains.meta?.total / domains.meta?.per_page}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={1}
                        onPageChange={changePage}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={page - 1}
                    />
                )}
            </div>
        </div>
    );
};

export default ListDomain;
