import { Col, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import './styles.scss';
import { useEffect, useState } from 'react';
import { changeOrderPages, deleteOrder, getBuyers, getOrders, updateOrder } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/UI/Button';
import { getTopic } from '../../SysAdmin/action';
import ModalAccept from '../../../components/Modals/ModalAccept';
import SelectSearch from '../../../components/UI/Select';

const ListOrder = () => {
    const orders = useSelector((state) => state.webMasterReducer.orders);
    const page = useSelector((state) => state.webMasterReducer.pageOrder);
    const topics = useSelector((state) => state.serverReducer.topics);
    const buyers = useSelector((state) => state.webMasterReducer.buyers);

    const [isLoading, setIsLoading] = useState(0);
    const [isShow, setIsShow] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [buyer, setBuyer] = useState('');
    const [status, setStatus] = useState('');

    const statusOptions = [
        { label: 'Closed', value: 'closed' },
        { label: 'Open', value: 'open' },
    ];
    const dispatch = useDispatch();

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changeOrderPages(currentPage));
        await dispatch(getOrders(currentPage));
    };

    const loadData = async () => {
        await dispatch(getOrders(page, buyer, status));
        await dispatch(getTopic());
        await dispatch(getBuyers(1));
    };

    const handleClickOrderDelete = async () => {
        await dispatch(deleteOrder(currentId));
        closeModal();
    };

    const showNameTopic = (id) => {
        const topic = topics.find((item) => item.id === id);
        if (topic) {
            return topic.topic;
        }

        return '-';
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleClickButtonClosed = async (item) => {
        setIsLoading(item.id);
        const options = {
            ...item,
            user_id: item.user?.id,
            status: 'closed',
        };

        await dispatch(updateOrder(item.id, options));
        setIsLoading(0);
    };

    const closeModal = () => {
        setIsShow(false);
        setCurrentId(null);
    };

    const openModal = (id) => {
        setCurrentId(id);
        setIsShow(true);
    };

    const getOptionsBuyers = () => {
        return (buyers?.data || []).map((item) => ({ label: item.name, value: item.id }));
    };

    const handleChangeSelect = async (value, id) => {
        if (id === 'buyer') {
            await setBuyer(value);
            await dispatch(getOrders(page, value, status));
            return;
        }
        if (id === 'status') {
            await setStatus(value);
            await dispatch(getOrders(page, buyer, value));
            return;
        }
    };

    return (
        <div>
            <Row style={{ marginBottom: '10px' }}>
                <Col xs={3}>
                    <SelectSearch
                        onChange={(e) => handleChangeSelect(e, 'buyer')}
                        title={'Байер'}
                        options={getOptionsBuyers()}
                        itemLabel={'label'}
                        itemValue={'value'}
                        default={buyer}
                        backspaceRemovesValue={false}
                        value={buyer}
                    />
                </Col>
                <Col xs={3}>
                    <SelectSearch
                        onChange={(e) => handleChangeSelect(e, 'status')}
                        title={'Статус'}
                        options={statusOptions}
                        backspaceRemovesValue={false}
                        itemLabel={'label'}
                        itemValue={'value'}
                        default={status}
                        value={status}
                    />
                </Col>
            </Row>

            <div className="ListOrder">
                <div className="d-flex flex-column">
                    <Row className="border-bottom pb-3 text-bold">
                        <Col className="ListOrder-item_header">ID</Col>
                        <Col className="ListOrder-item_header">Name domain</Col>
                        <Col className="ListOrder-item_header">Buyer</Col>
                        <Col className="ListOrder-item_header">Topic</Col>
                        <Col className="ListOrder-item_header">Comment</Col>
                        <Col className="ListOrder-item_header">Status</Col>
                        <Col className="ListOrder-item_header"></Col>
                    </Row>
                    {orders.data.map((item) => (
                        <Row className={`border-bottom pb-3 pt-4 d-flex list`} key={item.id}>
                            <Col className="ListOrder-item">{item.id}</Col>
                            <Col className="ListOrder-item">{item.domain_name}</Col>
                            <Col className="ListOrder-item">{item.user?.name}</Col>
                            <Col className="ListOrder-item">{showNameTopic(item.topic_id)}</Col>
                            <Col className="ListOrder-item">{item.desc}</Col>
                            <Col className="ListOrder-item">{item.status}</Col>
                            <Col className="ListOrder-buttons">
                                {item?.status !== 'closed' && (
                                    <Button
                                        onClick={() => handleClickButtonClosed(item)}
                                        blue
                                        title="Closed"
                                        isLoading={isLoading === item.id}
                                        className="ListOrder-buttons-button"
                                    />
                                )}
                            </Col>
                        </Row>
                    ))}
                </div>
                <div className="pagination">
                    {orders.meta?.total > orders.meta?.per_page && (
                        <ReactPaginate
                            previousLabel="<"
                            nextLabel=">"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={orders.meta?.total / orders.meta?.per_page}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={1}
                            onPageChange={changePage}
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={page - 1}
                        />
                    )}
                </div>
            </div>
            <ModalAccept
                regenerate={handleClickOrderDelete}
                title="Do you want delete this order ?"
                closeModal={closeModal}
                show={isShow}
                isLoading={isLoading}
            />
        </div>
    );
};

export default ListOrder;
