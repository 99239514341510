import { Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import './styles.scss';
import ReactPaginate from 'react-paginate';
import UICardContainer from '../../../components/UI/UICardContainer';
import Button from '../../../components/UI/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../core/helpers/routes';
import { useDispatch, useSelector } from 'react-redux';
import { changePages, getServers } from '../action';
import moment from 'moment';
import ModalCreateServer from '../../../components/Modals/ModalCreateServer';
import ModalCreateGeneralDomain from '../../../components/Modals/ModalCreateGeneralDomain';
import ModalCreatePreset from '../../../components/Modals/ModalCreatePreset';
import ModalAddedCriterion from '../../../components/Modals/ModalAddedCriterion';

const PanelSysAdmin = () => {
    const servers = useSelector((state) => state.serverReducer.servers);
    const page = useSelector((state) => state.serverReducer.page);
    const [isShow, setIsShow] = useState(false);
    const [isShowPreset, setIsShowPreset] = useState(false);
    const [isShowGeneral, setIsShowGeneral] = useState(false);
    const [isOpenDefault, setIsOpenDefault] = useState(false);

    const handleOpenCloseDefault = () => {
        setIsOpenDefault(!isOpenDefault);
    };

    const closeOpenModal = () => {
        setIsShow(!isShow);
    };

    const closeOpenModalGeneral = () => {
        setIsShowGeneral(!isShowGeneral);
    };

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const loadData = async () => {
        await dispatch(getServers(page));
    };

    useEffect(() => {
        loadData();
    }, []);

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePages(currentPage));
        await dispatch(getServers(currentPage));
    };

    const handleClickMore = (id) => {
        navigate(ROUTES.domeins.path.replace(':id', id));
    };

    const formatDate = (inputDate) => {
        const date = moment(inputDate);
        return date.format('DD.MM.YYYY');
    };

    const closeModalPreset = () => {
        setIsShowPreset(false);
    };

    const openModalPreset = () => {
        setIsShowPreset(true);
    };

    return (
        <UICardContainer
            title="Панель системного администратора"
            titleActionButton="Добавить Server"
            action={closeOpenModal}
            titleActionButtonTwo="Добавить General Domain"
            actionTwo={closeOpenModalGeneral}
            titleActionButtonThree="Добавить Preset"
            actionThree={openModalPreset}
            titleActionButtonFour="Change Default Filter"
            actionFour={handleOpenCloseDefault}
        >
            <div className="d-flex flex-column">
                <Row xs={9} className="border-bottom pb-3 text-bold">
                    <Col className="item_header">ID</Col>
                    <Col className="item_header">HP</Col>
                    <Col className="item_header">IP</Col>
                    <Col className="item_header">Start date</Col>
                    <Col className="item_header">Expiration date</Col>
                    <Col className="item_header">Update date</Col>
                    <Col className="item_header">GEO</Col>
                    <Col className="item_header">Domains</Col>
                    <Col className="item_header"></Col>
                    <Col className="item_header"></Col>
                    <Col className="item_header"></Col>
                </Row>
                {servers.data.map((item) => (
                    <Row xs={9} className={`border-bottom pb-3 pt-4`} key={item.id}>
                        <Col className="item">{item.id}</Col>
                        <Col className="item">{item.host_provider_name}</Col>
                        <Col className="item">{item.ip}</Col>
                        <Col className="item">{formatDate(item.start_date)}</Col>
                        <Col className="item">{formatDate(item.expiration_date)}</Col>
                        <Col className="item">{formatDate(item.updated_at)}</Col>
                        <Col className="item">{item.geolocation}</Col>
                        <Col style={{ cursor: 'pointer' }} className="item" onClick={() => handleClickMore(item.id)}>
                            More
                        </Col>
                        <Col className="item"></Col>
                        <Col className="item"></Col>
                        <Col className="item">
                            <Button title="Login key" />
                        </Col>
                    </Row>
                ))}
            </div>
            <div className="pagination">
                {servers.meta?.total > servers.meta?.per_page && (
                    <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={servers.meta?.total / servers.meta?.per_page}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={1}
                        onPageChange={changePage}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={page - 1}
                    />
                )}
            </div>
            <ModalCreateServer show={isShow} closeModal={closeOpenModal} loadServer={loadData} />
            {isShowGeneral && <ModalCreateGeneralDomain show={isShowGeneral} closeModal={closeOpenModalGeneral} />}
            {isShowPreset && <ModalCreatePreset show={isShowPreset} closeModal={closeModalPreset} />}
            {isOpenDefault && <ModalAddedCriterion show={isOpenDefault} closeModal={handleOpenCloseDefault} defaultRule />}
        </UICardContainer>
    );
};

export default PanelSysAdmin;
