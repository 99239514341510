import { NotificationManager } from 'react-notifications';
import { API } from '../../core/api';
import { CHANGE_PAGE_USERS, SET_ROLES, SET_USERS } from './types';
import { helperError } from '../../core/helpers/helperError';

export const getUsers = (page) => async (dispatch) => {
    try {
        const resp = await API.get(`/admin/users?page=${page}`);

        dispatch({ type: SET_USERS, payload: resp.data });
    } catch (error) {
        helperError(error);
    }
};

export const activatedUser = (userId) => async () => {
    try {
        const resp = await API.post(`/admin/users/activate/${userId}`);
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const deactivatedUser = (userId) => async () => {
    try {
        const resp = await API.post(`/admin/users/deactivate/${userId}`);
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const deactivatedRole = (userId, role) => async () => {
    try {
        const resp = await API.post(`/admin/users/revoke-role-from-user/${userId}/${role}`);
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const setRoleUser = (userId, role) => async () => {
    try {
        const resp = await API.post(`/admin/users/assign-role-to-user/${userId}/${role}`);
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const getRoles = () => async (dispatch) => {
    try {
        const resp = await API.get(`/admin/roles`);
        dispatch({ type: SET_ROLES, payload: resp.data.data });
        return resp.data.data;
    } catch (error) {
        helperError(error);
    }
};

export const changePages = (page) => async (dispatch) => {
    dispatch({ type: CHANGE_PAGE_USERS, payload: page });
};
