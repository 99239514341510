import { Button, Col, Modal, Row, Spinner, ListGroup } from 'react-bootstrap';
import './styles.scss';
import UIFormInput from '../../UI/UIFormInput';
import { useDispatch } from 'react-redux';
import { binomConnect, getListBinom } from '../../../pages/Bayer/actions';
import { useEffect, useState } from 'react';

const ModalCreateBinom = ({ closeModal, show, loadData }) => {
    const [build, setBuild] = useState({
        service_url: '',
        binom_user_id: '',
    });

    const [isLoading, setIsLoading] = useState(false);
    const [listBinom, setListBinom] = useState([]);

    const dispatch = useDispatch();

    const closedModal = () => {
        closeModal();
        setBuild({ service_url: '', binom_user_id: '' });
    };

    const handleChangeInput = (e) => {
        const value = e.target.value;
        const key = e.target.id;
        setBuild({ ...build, [key]: value });
    };

    const isDisableButton = () => {
        if (build.service_url.length === 0) return true;
        if (build.binom_user_id.length === 0) return true;
        if (isLoading) return true;
        return false;
    };

    const connectBinom = async () => {
        setIsLoading(true);
        const options = {
            service_url: build.service_url,
            service_data: { binom_user_id: build.binom_user_id },
        };
        const resp = await dispatch(binomConnect(options));
        if (resp) {
            closedModal();
            loadData();
        }
        setIsLoading(false);
    };

    const loadBinomList = async () => {
        const resp = await dispatch(getListBinom());
        if (resp) {
            const list = resp?.data?.map((item) => ({ label: item, value: item }));
            setListBinom(list);
        }
    };

    useEffect(() => {
        loadBinomList();
    }, []);

    return (
        <Modal show={show} onHide={closedModal} className="modal-binom">
            <Modal.Header closeButton>
                <Modal.Title>Подключить Binom</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={8} className="CreateBinom">
                        <Row className="d-flex flex-column">
                            <Col xs={12}>
                                <UIFormInput
                                    onChange={handleChangeInput}
                                    type="text"
                                    id="service_url"
                                    label={'Ссылка на Binom'}
                                    value={build?.service_url ? build?.service_url : ''}
                                />
                            </Col>
                            <Col xs={12}>
                                <UIFormInput
                                    onChange={handleChangeInput}
                                    type="text"
                                    id="binom_user_id"
                                    label={'User ID'}
                                    value={build?.binom_user_id ? build?.binom_user_id : ''}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={4} style={{ paddingTop: '7px' }}>
                        <span className="list-binom-title">Список добавленных:</span>
                        <div className="list-binom">
                            {listBinom.map((binom, index) => (
                                <div key={index}>{binom.label}</div>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div className="CreateDomain-buttons">
                    <Button
                        style={{ background: 'silver' }}
                        className="wrapper-button-added-path-button"
                        variant="secondary"
                        onClick={closedModal}
                    >
                        Отмена
                    </Button>
                    <Button
                        className="wrapper-button-added-path-button"
                        disabled={isDisableButton()}
                        variant="primary"
                        onClick={connectBinom}
                    >
                        {isLoading ? <Spinner size="sm" animation="border" /> : 'Подключить'}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalCreateBinom;
