// Функція для витягування унікальних компаній
export const getCompanyOptions = (statsForGraph) => {
    if (!statsForGraph) return [];
    const companies = statsForGraph?.map((item) => ({
        label: item.company.name,
        value: item.company.name,
    }));
    return [...new Set(companies.map((c) => JSON.stringify(c)))].map((c) => JSON.parse(c));
};

// Функція для витягування унікальних груп
export const getGroupOptions = (statsForGraph) => {
    if (!statsForGraph) return [];

    const groups = statsForGraph?.flatMap((item) =>
        item.company.adGroups.map((group) => ({
            label: group.name,
            value: group.name,
        })),
    );
    return [...new Set(groups.map((g) => JSON.stringify(g)))].map((g) => JSON.parse(g));
};

// Функція для витягування унікальних об’яв
export const getAdvertisementOptions = (statsForGraph) => {
    if (!statsForGraph) return [];

    const advertisements = statsForGraph?.flatMap((item) =>
        item.company.adGroups.flatMap((group) =>
            group.ads.map((ad) => ({
                label: ad.id, // або ad.name, залежно від вашої структури
                value: ad.id,
            })),
        ),
    );
    return [...new Set(advertisements.map((ad) => JSON.stringify(ad)))].map((ad) => JSON.parse(ad));
};
