import { Col, Row } from 'react-bootstrap';
import './styles.scss';
import UIFormInput from '../../UI/UIFormInput';
import SelectSearch from '../../UI/Select';
import { geo } from '../../../utils/data';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getLocations } from '../../../pages/Bayer/actions';

const ModalThankYouPage = ({ domain, push = false, openScript, build, setBuild }) => {
    const [optionsLocal, setOptionsLocal] = useState([]);

    const dispatch = useDispatch();

    const loadLocales = async () => {
        const resp = await dispatch(getLocations());
        if (resp) {
            const options = resp.data.map((item) => ({ label: item, value: item }));
            setOptionsLocal(options);
        }
    };
    const handleChangeInput = (e) => {
        const value = e.target.value;
        const key = e.target.id;
        setBuild({ ...build, [key]: value });
    };

    const handleChangeSelect = (item, id) => {
        setBuild({ ...build, [id]: item });
    };

    const showLink = () => {
        if (domain?.domain_data?.thank_you_page) {
            if (push) {
                if (domain?.domain_data?.thank_you_page?.ty) {
                    return `https://${domain.name}/${domain?.domain_data?.thank_you_page?.ty}`;
                }
                return null;
            }
        }
        if (domain?.domain_data?.success_page) {
            if (domain?.domain_data?.success_page?.success) {
                return `https://${domain.name}/${domain?.domain_data?.success_page?.success}`;
            }
            return null;
        }

        return null;
    };

    useEffect(() => {
        loadLocales();
    }, []);

    return (
        <div className="ThankYouPage">
            <div className="ThankYouPage-header">
                <div className="ThankYouPage-title">
                    {/* <h2> Генерация Thanks Page</h2> */}
                    <span>
                        {push ? 'Пуш' : 'Витрина'} {showLink() !== null ? ` - ${showLink()}` : null}
                    </span>
                </div>
            </div>
            <div className="ThankYouPage-content">
                <Row>
                    <Col xs={6}>
                        <UIFormInput
                            onChange={handleChangeInput}
                            type="text"
                            id="full_valid_number_code_conversion_ads"
                            label="Полный ADS код 'Валидный номер'"
                            placeholder="11520506744/kk1FFVM-444UZEJTttfs2"
                            value={
                                build?.full_valid_number_code_conversion_ads ? build?.full_valid_number_code_conversion_ads : ''
                            }
                        />
                    </Col>
                    <Col xs={6}>
                        <UIFormInput
                            onChange={handleChangeInput}
                            type="text"
                            id="ga_code"
                            label="GA Analytics код"
                            placeholder="G-HEV4B0ZW44"
                            value={build?.ga_code ? build?.ga_code : ''}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <UIFormInput
                            onChange={handleChangeInput}
                            type="text"
                            id="full_visit_code_conversion_ads"
                            label="Полный ADS код 'Посещение'"
                            placeholder="11520506744/FeFFVM-444UZEJuxreYp"
                            value={build?.full_visit_code_conversion_ads ? build?.full_visit_code_conversion_ads : ''}
                        />
                    </Col>
                    <Col xs={6}>
                        <UIFormInput
                            onChange={handleChangeInput}
                            type="text"
                            id="short_code_conversion_ads"
                            label={'Короткий ADS код'}
                            placeholder="11520506744"
                            value={build?.short_code_conversion_ads ? build?.short_code_conversion_ads : ''}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <SelectSearch
                            onChange={(e) => handleChangeSelect(e, 'geo')}
                            title={'GEO'}
                            options={geo}
                            itemLabel={'label'}
                            itemValue={'value'}
                            default={build?.geo}
                        />
                    </Col>
                    <Col xs={6}>
                        <SelectSearch
                            onChange={(e) => handleChangeSelect(e, 'locale')}
                            title={'Язык'}
                            options={optionsLocal}
                            itemLabel={'label'}
                            itemValue={'value'}
                            default={build?.locale}
                        />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={12}>
                        <textarea
                            id="custom_code"
                            onChange={handleChangeInput}
                            placeholder="Кастомный код (вставляется перед закрытием Body)"
                            className="ThankYouPage-textarea"
                            value={build?.custom_code}
                        ></textarea>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ModalThankYouPage;
