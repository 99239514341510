import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import UIFormInput from '../UI/UIFormInput';
import SelectSearch from '../UI/Select';
import { optionsTypePage } from '../../helpers/options';
import { useDispatch, useSelector } from 'react-redux';
import SelectSearchMulti from '../UI/SelectIsMulti';
import {
    createWhitePage,
    editWhitePage,
    generatePrompt,
    getDalleModels,
    getGptModel,
    getLocales,
    getThemes,
    getWhitePage,
} from './actions';
import { changeStatusDomainWarm } from '../../pages/Farmer/actions';
import { NotificationManager } from 'react-notifications';
import './styles.scss';
import UIFormTextaria from '../UI/UIFormTextaria';

const GeneratorWhitePage = ({ activeDomain, closedModal, loadWhite }) => {
    const themes = useSelector((state) => state.whitePageReducer.themes);
    const dalle = useSelector((state) => state.whitePageReducer.dalle);
    const locales = useSelector((state) => state.whitePageReducer.locales);
    const gptModels = useSelector((state) => state.whitePageReducer.gptModels);

    const loadData = () => {
        if (gptModels.length === 0) {
            dispatch(getGptModel());
        }
        if (dalle.length === 0) {
            dispatch(getDalleModels());
        }
        // if (themes.length === 0) {
        dispatch(getThemes());
        // }
        if (locales.length === 0) {
            dispatch(getLocales());
        }
    };

    const [build, setBuild] = useState({
        name: '',
        theme: null,
        topic: '',
        domain_name: '',
        site_type: null,
        locale: null,
        available_locales: [],
        chat_gpt_model: null,
        dalle_model: null,
        use_ai: true,
        tag_id: '',
        contacts_information: {
            addresses: {
                uk_UA: '',
                en_US: '',
                ru_RU: '',
            },
        },
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPrompt, setIsLoadingPrompt] = useState(false);

    const dispatch = useDispatch();

    const handleChangeInput = (e) => {
        const value = e.target.value;
        const key = e.target.id;
        setBuild({ ...build, [key]: value });
    };

    const handleChangeInputAddress = (e) => {
        const value = e.target.value;
        const key = e.target.id;

        setBuild({
            ...build,
            contacts_information: {
                ...build.contacts_information,
                addresses: { ...build.contacts_information.addresses, [key]: value },
            },
        });
    };

    const handleChangeSelect = (item, id) => {
        if (id === 'locale') {
            setBuild({ ...build, available_locales: [item], [id]: item });
            return;
        }
        setBuild({ ...build, [id]: item });
    };

    const randomizer = async () => {
        if (themes.length > 0) {
            const randomIndex = Math.floor(Math.random() * themes.length);
            const randomTheme = themes[randomIndex];
            await setBuild({ ...build, theme: randomTheme.value });
        }
    };

    useEffect(() => {
        randomizer();
    }, [themes]);

    const handleClickButton = async () => {
        if (activeDomain?.domain_data?.white_page?.white_id) {
            setIsLoading(true);

            const data = await dispatch(editWhitePage(build, activeDomain?.domain_data?.white_page?.white_id));
            if (data) {
                loadWhite();
                closedModal();
                setIsLoading(false);
            }

            return;
        }

        setIsLoading(true);
        const data = await dispatch(createWhitePage(build));
        if (data) {
            const options = {
                topic_id: activeDomain['topic'].id,
                name: activeDomain.name,
                registrar: activeDomain.registrar,
                domain_ip: activeDomain.domain_ip,
                domain_ip_alt: activeDomain.domain_ip_alt,
                server_id: activeDomain.server.id,
                status: activeDomain.status,
                user_id: activeDomain.user_id,
                domain_data: {
                    white_page: { white_id: data.id },
                },
            };
            await dispatch(changeStatusDomainWarm(options, activeDomain.id));
            setBuild({
                name: '',
                theme: null,
                topic: '',
                domain_name: '',
                site_type: null,
                locale: null,
                available_locales: [],
                chat_gpt_model: null,
                dalle_model: null,
                use_ai: true,
                contacts_information: {
                    addresses: {
                        uk_UA: '',
                        en_US: '',
                        ru_RU: '',
                    },
                },
            });
            loadWhite();
            closedModal();
        }
        setIsLoading(false);
    };

    const handleClickGeneratePromt = async () => {
        setIsLoadingPrompt(true);
        const options = {
            prompt: build.topic,
            chat_gpt_model: build.chat_gpt_model,
        };
        const response = await dispatch(generatePrompt(options));

        if (response?.data?.extended_prompt) {
            setBuild({ ...build, topic: response?.data?.extended_prompt });
        }

        setIsLoadingPrompt(false);
    };

    const isDisabledButton = () => {
        if (build.name.length === 0) return true;
        else if (build.domain_name.length === 0) return true;
        else if (build.locale === null) return true;
        else if (build.chat_gpt_model === null) return true;
        else if (build.dalle_model === null) return true;
        else if (build.theme === null) return true;
        else if (build.site_type === null) return true;
        else if (build.topic.length === 0) return true;
        else if (build.available_locales.length === 0) return true;
        return false;
    };

    const isDisabledButtonPrompt = () => {
        if (build.chat_gpt_model === null) return true;
        else if (build.topic.length === 0) return true;
        return false;
    };

    const loadWhitePageById = async () => {
        const whitePage = await dispatch(getWhitePage(activeDomain?.domain_data?.white_page?.white_id));

        if (whitePage) {
            setBuild({
                ...build,
                name: whitePage.name,
                theme: whitePage.theme,
                site_type: whitePage.site_type,
                topic: whitePage.topic,
                locale: whitePage.locale,
                available_locales: whitePage.available_locales,
                domain_name: whitePage.name,
                contacts_information: whitePage.contacts_information,
            });
            return;
        }

        const options = {
            topic_id: activeDomain['topic'].id,
            name: activeDomain.name,
            registrar: activeDomain.registrar,
            domain_ip: activeDomain.domain_ip,
            domain_ip_alt: activeDomain.domain_ip_alt,
            server_id: activeDomain.server.id,
            status: activeDomain.status,
            user_id: activeDomain.user_id,
            domain_data: {
                white_page: { white_id: null },
            },
        };
        await dispatch(changeStatusDomainWarm(options, activeDomain.id, true));
        loadWhite();
        NotificationManager.error(`Попробуйте ещё раз`, '', 3500);
        closedModal();
    };

    useEffect(() => {
        loadData();
        if (activeDomain) {
            setBuild({ ...build, domain_name: activeDomain.name });
        }
        if (activeDomain?.domain_data?.white_page?.white_id) {
            loadWhitePageById();
        }
    }, []);

    return (
        <div className="p-4 w-100 pb-0">
            <Row>
                <Col xs={4}>
                    <UIFormInput
                        onChange={handleChangeInput}
                        type="text"
                        id="name"
                        label={'Название архива'}
                        value={build?.name ? build?.name : ''}
                    />
                </Col>
                <Col xs={4}>
                    <SelectSearch
                        onChange={(e) => handleChangeSelect(e, 'theme')}
                        title={'Тема'}
                        options={themes}
                        itemLabel={'label'}
                        itemValue={'value'}
                        default={build?.theme}
                        value={build?.theme}
                    />
                </Col>
                <Col xs={4}>
                    <SelectSearch
                        onChange={(e) => handleChangeSelect(e, 'site_type')}
                        title={'Тип страницы'}
                        options={optionsTypePage}
                        itemLabel={'label'}
                        itemValue={'value'}
                        default={build?.site_type}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={4}>
                    <UIFormInput
                        onChange={handleChangeInput}
                        type="text"
                        id="tag_id"
                        label={'Google Ads Tag ID'}
                        value={build?.tag_id ? build?.tag_id : ''}
                    />
                </Col>
                <Col xs={4}>
                    <SelectSearch
                        onChange={(e) => handleChangeSelect(e, 'locale')}
                        title={'Основной язык'}
                        options={locales}
                        itemLabel={'label'}
                        itemValue={'value'}
                        default={build?.locale}
                    />
                </Col>
                <Col xs={4}>
                    <SelectSearch
                        onChange={(e) => handleChangeSelect(e, 'chat_gpt_model')}
                        title={'Версия чата GPT'}
                        options={gptModels}
                        itemLabel={'label'}
                        itemValue={'value'}
                        default={build?.chat_gpt_model}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={4}>
                    <UIFormInput
                        onChange={handleChangeInput}
                        type="text"
                        id="domain_name"
                        label={'Название домена'}
                        value={build?.domain_name ? build?.domain_name : ''}
                    />
                </Col>
                <Col xs={4}>
                    <SelectSearchMulti
                        onChange={(e) => handleChangeSelect(e, 'available_locales')}
                        title={'Доступные языки вайта'}
                        options={locales}
                        itemLabel={'label'}
                        itemValue={'value'}
                        values={build?.available_locales}
                        default={build?.available_locales}
                    />
                </Col>
                <Col xs={4}>
                    <SelectSearch
                        onChange={(e) => handleChangeSelect(e, 'dalle_model')}
                        title={'Версия Dall-e'}
                        options={dalle}
                        itemLabel={'label'}
                        itemValue={'value'}
                        default={build?.dalle_model}
                    />
                </Col>
            </Row>
            {build?.available_locales?.length !== 0 && (
                <Row>
                    {build?.available_locales?.includes('en_US') && (
                        <Col xs={4}>
                            <UIFormInput
                                onChange={handleChangeInputAddress}
                                type="text"
                                id="en_US"
                                label={'Адрес US'}
                                value={
                                    build?.contacts_information?.addresses?.en_US
                                        ? build?.contacts_information?.addresses?.en_US
                                        : ''
                                }
                            />
                        </Col>
                    )}
                    {build?.available_locales?.includes('ru_RU') && (
                        <Col xs={4}>
                            <UIFormInput
                                onChange={handleChangeInputAddress}
                                type="text"
                                id="ru_RU"
                                label={'Адрес RU'}
                                value={
                                    build?.contacts_information?.addresses?.ru_RU
                                        ? build?.contacts_information?.addresses?.ru_RU
                                        : ''
                                }
                            />
                        </Col>
                    )}
                    {build?.available_locales?.includes('uk_UA') && (
                        <Col xs={4}>
                            <UIFormInput
                                onChange={handleChangeInputAddress}
                                type="text"
                                id="uk_UA"
                                label={'Адрес UA'}
                                value={
                                    build?.contacts_information?.addresses?.uk_UA
                                        ? build?.contacts_information?.addresses?.uk_UA
                                        : ''
                                }
                            />
                        </Col>
                    )}
                </Row>
            )}
            <Row>
                <Col xs={12}>
                    <UIFormTextaria
                        onChange={handleChangeInput}
                        type="text"
                        className="input-textaria"
                        id="topic"
                        label={'Промт сайта'}
                        value={build?.topic ? build?.topic : ''}
                    />
                </Col>
            </Row>
            {/* <Row>
        <Col className="d-flex flex-column mt-3 gap-1" xs={4}>
          <Col xs={12}>
            <Form.Check
              onChange={handleChangeCheckbox}
              id="use_ai"
              label="Использовать ИИ"
              className="ml-1"
              checked={build?.use_ai ? build?.use_ai : false}
            />
          </Col>
        </Col>
      </Row> */}
            <Row className="mt-3">
                <Col xs={2} className="ml-1 d-flex gap-2">
                    <Button
                        onClick={handleClickButton}
                        disabled={isDisabledButton()}
                        variant="primary"
                        style={{ width: '142px', height: '38px' }}
                    >
                        {isLoading ? <Spinner animation="border" size="sm" /> : 'Cгенерировать'}
                    </Button>
                </Col>
                <Col className="ml-1 d-flex gap-2">
                    <Button
                        onClick={handleClickGeneratePromt}
                        disabled={isDisabledButtonPrompt()}
                        variant="primary"
                        style={isLoadingPrompt ? { width: '193px', height: '38px' } : {}}
                    >
                        {isLoadingPrompt ? <Spinner animation="border" size="sm" /> : 'Cгенерировать промт'}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default GeneratorWhitePage;
