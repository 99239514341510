export const ROUTES = {
    index: { main: '/', container: '*' },
    authorization: { path: '/authorization', link: '/authorization' },
    register: { path: '/register', link: '/register' },
    page: { path: '/generatorBlackPage', link: '/generatorBlackPage' },
    domeins: {
        path: '/domains/:id',
        link: '/domains/:id',
    },
    statistic: { path: '/statistic', link: '/statistic/:name' },
    domeinsEdit: {
        path: '/domainsEdit/:id',
        link: '/domainsEdit',
    },
    domeinsCreate: {
        path: '/domainsCreate',
        link: '/domainsCreate',
    },
    hostings: {
        path: '/hosting-list',
        link: '/hosting-list',
    },
    hostingEdit: {
        path: '/hostingEdit/:id',
        link: '/hostingEdit',
    },
    hostingCreate: {
        path: '/hostingCreate',
        link: '/hostingCreate',
    },
    accountCreate: {
        path: '/account-create',
        link: '/account-create',
    },
    accountEdit: {
        path: '/account-edit/:idHosting/:idAccount',
        link: '/account-edit',
    },

    filters: {
        path: '/filters',
        link: '/filters',
    },
    sysAdminPanel: {
        path: '/sysadmin-panel',
        link: '/sysadmin-panel',
    },
    helperSysAdminPanel: {
        path: '/helper-sysadmin-panel',
        link: '/helper-sysadmin-panel',
    },
    panelFarmer: {
        path: '/panel-farmer',
        link: '/panel-farmer',
    },
    panelBayer: {
        path: '/panel-buyer',
        link: '/panel-buyer',
    },

    listBuyer: {
        path: '/list-buyer',
        link: '/list-buyer',
    },

    listDomainBuyer: {
        path: '/list-domain/:userId',
        link: '/list-domain/:userId',
    },

    listUsers: {
        path: 'list-users',
        link: 'list-users',
    },

    showWhitePage: { path: '/white-page/:id', link: '/white-page/:id' },
};
