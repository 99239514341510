import axios from 'axios';
import { helperError } from '../../../core/helpers/helperError';
import { API } from '../../../core/api';

export const getServices = (domain) => async () => {
    try {
        const response = await axios.get(`https://dev.tds.13.tools/api/v1/filtering/domain/${domain}/services`);

        return response.data;
    } catch (e) {
        // NotificationManager.error(`${e.message}`, "", 3500);
    }
};

export const getListServices = () => async () => {
    try {
        const response = await axios.get(`https://dev.tds.13.tools/api/v1/filtering/services/list`);

        return response.data;
    } catch (e) {
        // NotificationManager.error(`${e.message}`, "", 3500);
    }
};

export const getAccountsBinom = (service, userId) => async () => {
    try {
        const response = await axios.get(`https://dev.tds.13.tools/api/v1/filtering/services/${service}/account/${userId}/list`);

        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const activatedService = (options, service, userId, domain) => async () => {
    try {
        const response = await axios.post(
            `https://dev.tds.13.tools/api/v1/filtering/domain/${domain}/services/${service}/user/${userId}`,
            options,
        );

        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const changeDataService = (options, service, domain) => async () => {
    try {
        const response = await axios.put(
            `https://dev.tds.13.tools/api/v1/filtering/domain/${domain}/services/update-config/${service}`,
            options,
        );

        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const changeActivatedService = (options, domain) => async () => {
    try {
        const response = await axios.put(`https://dev.tds.13.tools/api/v1/filtering/domain/${domain}/services`, options);

        return response.data;
    } catch (e) {
        // NotificationManager.error(`${e.message}`, "", 3500);
    }
};

export const getListBinomService = (service, userId) => async () => {
    try {
        const response = await axios.get(`https://dev.tds.13.tools/api/v1/filtering/services/${service}/account/${userId}/list`);

        return response.data;
    } catch (e) {
        // NotificationManager.error(`${e.message}`, "", 3500);
    }
};

export const binomConnectService = (service, userId, options) => async () => {
    try {
        const response = await axios.post(
            `https://dev.tds.13.tools/api/v1/filtering/services/${service}/account/${userId}/connect`,
            options,
        );

        return response.data;
    } catch (e) {
        // NotificationManager.error(`${e.message}`, "", 3500);
    }
};

export const getStatic = (info) => async () => {
    try {
        const response = await axios.get(`https://dev.tds.13.tools/api/v1/static/get/${info}`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};
