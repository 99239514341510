import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import './styles.scss';
import UIFormInput from '../../UI/UIFormInput';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getListBinomService, binomConnectService } from '../../Binom/Services/actions';
import { ReactComponent as Question } from '../../../assets/images/question.svg';
import { vaultService } from '../../../core/services/vault-service';
import { Tooltip } from 'react-tooltip';
import { infoOperation } from '../../../helpers/data';

const ModalCreateBinomService = ({ closeModal, show, loadData, service }) => {
    const [build, setBuild] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [listBinom, setListBinom] = useState([]);
    const [fields, setFields] = useState([]);

    const vaultServices = vaultService();

    const { id } = vaultServices.getItem('user');

    const formatPlaceholder = (key) => {
        const parts = key.split('.');
        const lastPart = parts[parts.length - 1];

        return lastPart
            .split('_')
            .map((word, index) => {
                if (index === 0) {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                }
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            })
            .join(' ');
    };

    const getFields = (param_rules) => {
        const arr = Object.keys(param_rules)
            .filter((key) => key !== 'service_data')
            .map((key) => {
                const values = param_rules[key];
                const type = values[values.length - 1];

                return {
                    id: key,
                    name: key,
                    required: values.includes('required'),
                    placeholder: formatPlaceholder(key),
                    type: type,
                };
            });

        setFields(arr);
    };

    const dispatch = useDispatch();

    const closedModal = () => {
        closeModal();
        setBuild(null);
    };

    const handleChangeInput = (e) => {
        const value = e.target.value;
        const key = e.target.id;

        const keys = key.split('.');
        let updatedBuild = { ...build };

        let current = updatedBuild;

        keys.forEach((k, index) => {
            if (index === keys.length - 1) {
                current[k] = value;
            } else {
                if (!current[k]) {
                    current[k] = {};
                }
                current = current[k];
            }
        });

        setBuild(updatedBuild);
    };

    const connectBinom = async () => {
        setIsLoading(true);
        const resp = await dispatch(binomConnectService(service.id, id, build));
        if (resp) {
            closedModal();
            loadData();
        }
        setIsLoading(false);
    };

    const loadBinomList = async () => {
        const resp = await dispatch(getListBinomService(service.id, id));
        if (resp) {
            const list = resp?.map((item) => ({ label: item, value: item }));
            setListBinom(list);
        }
    };

    useEffect(() => {
        loadBinomList();
        getFields(service.account_rules);
    }, []);

    const getNestedValue = (obj, keys) => {
        return keys.reduce((value, key) => {
            return value && value[key] !== undefined ? value[key] : '';
        }, obj);
    };

    const showInfoAccount = () => {
        return (
            <div className="info_norm_buyer">
                <Question className="question-icon" data-tooltip-id="question-tooltip-accounts" />
                <Tooltip
                    id="question-tooltip-accounts"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '350px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p
                            className="m-0 p-0 w-100"
                            dangerouslySetInnerHTML={{ __html: service?.info?.accounts?.replace(/\n/g, '<br/>') }}
                        />
                    </div>
                </Tooltip>
            </div>
        );
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-binom">
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center gap-2">Подключить Binom {showInfoAccount()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={8} className="CreateBinom">
                        <Row className="d-flex flex-column">
                            {fields.map((item) => (
                                <Col xs={12}>
                                    <UIFormInput
                                        onChange={handleChangeInput}
                                        type="text"
                                        id={item.id}
                                        label={item.placeholder}
                                        value={getNestedValue(build, item.id.split('.'))}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col xs={4} style={{ paddingTop: '7px' }}>
                        <span className="list-binom-title">Список добавленных:</span>
                        <div className="list-binom">
                            {listBinom.map((binom, index) => (
                                <div key={index}>{binom.label}</div>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div className="CreateDomain-buttons">
                    <Button
                        style={{ background: 'silver' }}
                        className="wrapper-button-added-path-button"
                        variant="secondary"
                        onClick={closedModal}
                    >
                        Отмена
                    </Button>
                    <Button className="wrapper-button-added-path-button" variant="primary" onClick={connectBinom}>
                        {isLoading ? <Spinner size="sm" animation="border" /> : 'Подключить'}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalCreateBinomService;
