import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { binomCreate, getDataRk, getListBinom, getStaticRk, paladiumCreate, updateRk } from '../../../pages/Bayer/actions';
import { useEffect, useState } from 'react';
import SelectSearch from '../../UI/Select';
import SelectSearchMulti from '../../UI/SelectIsMulti';

const ModalCreateRK = ({ domain, userId, edit, selectedBinom, setSelectedBinom, build, setBuild }) => {
    const [listBinom, setListBinom] = useState([]);
    const [country, setCountry] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const loadData = async () => {
        const resp = await dispatch(getListBinom(userId));
        const list = resp.data.map((item) => ({ label: item, value: item }));
        const staticRk = await dispatch(getStaticRk(domain?.id));
        if (staticRk) {
            const language = Object.entries(staticRk?.language).map(([key, value]) => ({ label: value, value: key }));
            const country = Object.entries(staticRk?.country).map(([key, value]) => ({
                label: value,
                value: key,
            }));

            setCountry(country);
            setLanguages(language);
        }
        setListBinom(list);

        if (edit) {
            const respEdit = await dispatch(getDataRk(domain.id));
            if (respEdit?.campaign_data?.data) {
                setBuild({
                    exclude_country: respEdit?.campaign_data?.data?.exclude_country || [],
                    include_country: respEdit?.campaign_data?.data?.include_country || [],
                    language: respEdit?.campaign_data?.data?.language || [],
                });
            }
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const isDisableButton = () => {
        if (edit) return false;
        if (selectedBinom === null) return true;
        if (isLoading) return true;
        return false;
    };

    const handleChangeSelect = (value) => {
        setSelectedBinom(value);
    };

    const createRK = async () => {
        if (edit) {
            setIsLoading(true);

            const optionsPaladium = {
                campaign_name: domain.name,
            };
            if (build.exclude_country.length !== 0) {
                optionsPaladium.exclude_country = build.exclude_country;
            }

            if (build.include_country.length !== 0) {
                optionsPaladium.include_country = build.include_country;
            }

            if (build.language.length !== 0) {
                optionsPaladium.language = build.language;
            }

            await dispatch(updateRk(domain.id, optionsPaladium));

            setIsLoading(false);

            return;
        }
        setIsLoading(true);
        const optionsPaladium = {
            campaign_name: domain.name,
        };
        const optionsBinom = {
            user_id: userId,
            binom_key: selectedBinom,
            name: domain.name,
        };

        if (build.exclude_country.length !== 0) {
            optionsPaladium.exclude_country = build.exclude_country;
        }

        if (build.include_country.length !== 0) {
            optionsPaladium.include_country = build.include_country;
        }

        if (build.language.length !== 0) {
            optionsPaladium.language = build.language;
        }

        await dispatch(paladiumCreate(domain.id, optionsPaladium));

        await dispatch(binomCreate(domain.id, optionsBinom));

        setIsLoading(false);
    };

    const handleChangeSelectMulti = (item, id) => {
        if (id === 'include_country') {
            if (item.length === 0) {
                setBuild({ ...build, [id]: item, exclude_country: [] });
            } else if (item.includes('ALL') && item.length > 1) {
                setBuild({ ...build, [id]: ['ALL'] });
            } else if (item.includes('ALL') && item.length === 1) {
                setBuild({ ...build, [id]: ['ALL'] });
            } else if (!item.includes('ALL') && build[id]?.includes('ALL')) {
                setBuild({ ...build, [id]: item });
            } else {
                setBuild({ ...build, [id]: item });
            }
        } else {
            setBuild({ ...build, [id]: item });
        }
    };

    return (
        <div className="CreateRK">
            {!edit && (
                <Row className="d-flex flex-column">
                    <Col xs={12}>
                        <SelectSearch
                            onChange={handleChangeSelect}
                            title={'Binom'}
                            options={listBinom}
                            itemLabel={'label'}
                            itemValue={'value'}
                            default={selectedBinom}
                        />
                    </Col>
                </Row>
            )}
            <Row className="d-flex flex-column">
                <Col xs={12}>
                    <SelectSearchMulti
                        onChange={(e) => handleChangeSelectMulti(e, 'include_country')}
                        title={'Настройка Palladium'}
                        options={country}
                        itemLabel={'label'}
                        placeholder={'Выбор страны'}
                        itemValue={'value'}
                        default={build.include_country}
                        values={build.include_country}
                    />
                </Col>
                {build?.include_country.find((item) => item === 'ALL') && (
                    <Col xs={12}>
                        <SelectSearchMulti
                            onChange={(e) => handleChangeSelectMulti(e, 'exclude_country')}
                            options={country.filter((item) => item.value !== 'ALL')}
                            itemLabel={'label'}
                            placeholder={'Исключить страны'}
                            itemValue={'value'}
                            default={build.exclude_country}
                            values={build.exclude_country}
                        />
                    </Col>
                )}
                <Col xs={12}>
                    <SelectSearchMulti
                        onChange={(e) => handleChangeSelectMulti(e, 'language')}
                        options={languages}
                        itemLabel={'label'}
                        placeholder={'Выбор языка'}
                        itemValue={'value'}
                        values={build.language}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default ModalCreateRK;
