/* eslint-disable */
import { CREATE_HOSTING, EDIT_HOSTING, SET_HOSTINGS } from './types';

const initialState = {
    hostings: {
        data: [],
        meta: {
            per_page: 15,
            last_page: 1,
            total: 1,
            current_page: 1,
        },
    },
};

const hostingsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_HOSTINGS:
            return { ...state, hostings: payload };
        case CREATE_HOSTING:
            return {
                ...state,
                hostings: {
                    ...state.hostings,
                    data: [...state.hostings.data, payload.data],
                },
            };
        case EDIT_HOSTING:
            return {
                ...state,
                hostings: {
                    ...state.hostings,
                    data: state.hostings.data.map((item) => {
                        if (item.id === payload.data.id) return payload.data;
                        return item;
                    }),
                },
            };
        default:
            return state;
    }
};

export default hostingsReducer;
