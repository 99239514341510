import { Col, Row } from 'react-bootstrap';
import './styles.scss';
import SelectSearch from '../../UI/Select';
import { useDispatch } from 'react-redux';
import { getPresets, getScripts, getVersionScript } from '../../../pages/Bayer/actions';
import UIFormInput from '../../UI/UIFormInput';
import { useEffect, useState } from 'react';

const ModalScripts = ({ build, setBuild, scripts, setScripts, versions, setVersions, presets, setPresets }) => {
    const [optionsPresetSecond, setOptionsPresetSecond] = useState([]);

    const dispatch = useDispatch();

    const loadDataScript = async (type) => {
        const preset = await dispatch(getPresets(type));
        if (preset) {
            const optionPreset = preset.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setOptionsPresetSecond(optionPreset);
        }
    };

    const handleChangeSelect = async (item, id) => {
        if (id === 'second_type') {
            if (item !== null) {
                loadDataScript(item);
                setBuild({ ...build, additional_preset_id: null, [id]: item });
                return;
            }
        }

        if (id === 'script') {
            if (item !== null) {
                const version = await dispatch(getVersionScript(item, build.type));
                if (version) {
                    const optionVersion = version.map((item) => ({
                        label: item,
                        value: item,
                    }));
                    setVersions(optionVersion);
                }
            }
        }
        setBuild({ ...build, [id]: item });
    };

    const loadData = async () => {
        if (build.script && build?.type) {
            const version = await dispatch(getVersionScript(build.script, build.type));
            if (version) {
                const optionVersion = version.map((item) => ({
                    label: item,
                    value: item,
                }));
                setVersions(optionVersion);
            }
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const typeScript = [
        { label: 'GP', value: 'GP' },
        { label: 'DAO', value: 'DAO' },
    ];

    const handleChangeInput = (e) => {
        const { value } = e.target;

        if (value.length > 9) return;

        setBuild({ ...build, source_id: value });
    };

    return (
        <div className="Scripts">
            <Row>
                <Col>
                    <SelectSearch
                        onChange={(e) => handleChangeSelect(e, 'script')}
                        title={'Выбрать скрипт'}
                        options={scripts}
                        itemLabel={'label'}
                        itemValue={'value'}
                        default={build?.script}
                        isDisabled={!build?.type}
                        value={build?.script}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <SelectSearch
                        onChange={(e) => handleChangeSelect(e, 'version')}
                        title={'Выбрать версию'}
                        options={versions}
                        itemLabel={'label'}
                        itemValue={'value'}
                        default={build?.version}
                        isDisabled={!build?.script}
                        value={build?.version}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <SelectSearch
                        onChange={(e) => handleChangeSelect(e, 'preset')}
                        title={'Выбрать пресет'}
                        options={presets}
                        itemLabel={'label'}
                        itemValue={'value'}
                        default={build?.preset}
                        value={build?.preset}
                    />
                </Col>
            </Row>
            {build?.type === 'DAO' && (
                <Row>
                    <Col xs={12}>
                        <SelectSearch
                            onChange={(e) => handleChangeSelect(e, 'second_type')}
                            title={'Дополнительный тип скриптов'}
                            options={typeScript}
                            itemLabel={'label'}
                            itemValue={'value'}
                            default={build?.second_type}
                            value={build?.second_type}
                        />
                    </Col>
                    <Col>
                        <SelectSearch
                            onChange={(e) => handleChangeSelect(e, 'additional_preset_id')}
                            title={'Дополнительный пресет'}
                            options={optionsPresetSecond}
                            itemLabel={'label'}
                            itemValue={'value'}
                            default={build?.additional_preset_id}
                            value={build?.additional_preset_id}
                        />
                    </Col>
                    <Col>
                        <UIFormInput
                            onChange={handleChangeInput}
                            type="text"
                            id="source_id"
                            label="Source id"
                            value={build?.source_id ? build?.source_id : ''}
                        />
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default ModalScripts;
