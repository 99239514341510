import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './styles.scss';

const SelectSearch = (props) => {
    const {
        onChange,
        default: defaultOption = '',
        className = '',
        isClearable = true,
        backspaceRemovesValue = true,
        isDisabled = false,
        itemLabel,
        itemValue,
        placeholder,
        id,
        value,
        styles,
        options,
        title,
        classNameTitle,
    } = props;

    const [selected, setSelected] = useState(defaultOption);

    useEffect(() => {
        if (defaultOption !== undefined && selected !== defaultOption) {
            setSelected(defaultOption);
        }
    }, [defaultOption]);

    const getSelectedOptions = () => {
        if (selected === '') return null;
        if (selected === null) return null;
        return getOptions().find((option) => option.value + '' === selected + '');
    };

    const getOptions = () => {
        const stringOptions = (options || []).map((item) => ({
            label: item[itemLabel],
            value: item[itemValue],
        }));
        return stringOptions;
    };

    const handleOnChange = (option) => {
        if (option === null) {
            setSelected('');
            onChange(null);
        } else {
            setSelected(option.value + '');
            onChange(option.value);
        }
    };

    return (
        <div className={`d-flex flex-column ${styles ? 'p-0' : 'p-1'}`}>
            <span className={`pb-1 font-weight-bold ${classNameTitle}`}>{title}</span>
            <Select
                className={'select ' + className}
                value={getSelectedOptions()}
                onChange={handleOnChange}
                isClearable={isClearable}
                backspaceRemovesValue={backspaceRemovesValue}
                options={getOptions()}
                placeholder={placeholder}
                id={id}
                isDisabled={isDisabled}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
        </div>
    );
};

export default SelectSearch;
