import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import './styles.scss';
import { useEffect, useState } from 'react';
import SelectSearch from '../../UI/Select';
import ModalThankYouPage from '../ModalThankYouPage';
import ModalCreateRK from '../ModalCreateRK';
import ModalScripts from '../ModalScripts';
import {
    binomCreate,
    connectScript,
    getPresets,
    getScripts,
    getTypeSetting,
    paladiumCreate,
    updateRk,
} from '../../../pages/Bayer/actions';
import { useDispatch } from 'react-redux';
import { createSuccessPage, createThankYouPage } from '../../GeneratorWhitePage/actions';
import { changeStatusDomainWarm, setWhitePageDomain } from '../../../pages/Farmer/actions';

const ModalSettingRK = ({ closeModal, show, domain, userId, edit, loadDomain }) => {
    const [selected, setSelected] = useState(null);
    const [optionsType, setOptionsType] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [buildTY, setBuildTY] = useState({});
    const [buildTYP, setBuildTYP] = useState({});
    const [selectedBinom, setSelectedBinom] = useState(null);
    const [buildRK, setBuildRK] = useState({
        language: [],
        exclude_country: [],
        include_country: [],
    });

    const [scripts, setScripts] = useState([]);
    const [versions, setVersions] = useState([]);
    const [presets, setPresets] = useState([]);

    const [buildScript, setBuildScript] = useState({});

    const dispatch = useDispatch();

    const handleChangeSelect = async (value) => {
        await defaultState();

        if (value === 'Витрина + Пуш' || value === 'Прямой Пуш') {
            setBuildScript({ type: 'GP' });
            loadDataScript('GP');
        }

        if (value === 'Прямое ДАО') {
            setBuildScript({ type: 'DAO' });
            loadDataScript('DAO');
        }

        setSelected(value);
    };

    const handleChangeTabs = (tab) => {
        setActiveTab(tab);
    };

    const loadData = async () => {
        const resp = await dispatch(getTypeSetting());
        const options = resp.map((item) => ({ label: item, value: item }));
        setOptionsType(options);
    };

    useEffect(() => {
        loadData();
        setActiveTab(0);
    }, [selected]);

    useEffect(() => {
        loadDomainData();
    }, []);

    const defaultState = () => {
        setActiveTab(1);
        setBuildTY({});
        setBuildTYP({});
        setSelectedBinom(null);
        setBuildRK({
            language: [],
            exclude_country: [],
            include_country: [],
        });
        setScripts([]);
        setVersions([]);
        setPresets([]);
        setBuildScript({});
    };

    const renderTabs = () => {
        switch (selected) {
            case 'Витрина':
                return (
                    <Row>
                        <div className="setting-header">
                            <span className={activeTab === 1 ? 'active-tab' : ''} onClick={() => handleChangeTabs(1)}>
                                Success Page
                            </span>
                            <span className={activeTab === 4 ? 'active-tab' : ''} onClick={() => handleChangeTabs(4)}>
                                СоздатьRK
                            </span>
                        </div>
                    </Row>
                );

            case 'Витрина + Пуш':
                return (
                    <Row>
                        <div className="setting-header">
                            <span className={activeTab === 1 ? 'active-tab' : ''} onClick={() => handleChangeTabs(1)}>
                                Success Page
                            </span>
                            <span className={activeTab === 2 ? 'active-tab' : ''} onClick={() => handleChangeTabs(2)}>
                                Пуши
                            </span>
                            <span className={activeTab === 3 ? 'active-tab' : ''} onClick={() => handleChangeTabs(3)}>
                                Thanks page (Пуши)
                            </span>
                            <span className={activeTab === 4 ? 'active-tab' : ''} onClick={() => handleChangeTabs(4)}>
                                СоздатьRK
                            </span>
                        </div>
                    </Row>
                );

            case 'Прямой Пуш':
                return (
                    <Row>
                        <div className="setting-header">
                            <span className={activeTab === 3 ? 'active-tab' : ''} onClick={() => handleChangeTabs(3)}>
                                Thanks page (Пуши)
                            </span>
                            <span className={activeTab === 2 ? 'active-tab' : ''} onClick={() => handleChangeTabs(2)}>
                                Скрипты
                            </span>
                            <span className={activeTab === 4 ? 'active-tab' : ''} onClick={() => handleChangeTabs(4)}>
                                СоздатьRK
                            </span>
                        </div>
                    </Row>
                );

            case 'Прямое ДАО':
                return (
                    <Row>
                        <div className="setting-header">
                            <span className={activeTab === 1 ? 'active-tab' : ''} onClick={() => handleChangeTabs(1)}>
                                Success Page
                            </span>
                            <span className={activeTab === 2 ? 'active-tab' : ''} onClick={() => handleChangeTabs(2)}>
                                Скрипты
                            </span>
                            <span className={activeTab === 4 ? 'active-tab' : ''} onClick={() => handleChangeTabs(4)}>
                                СоздатьRK
                            </span>
                        </div>
                    </Row>
                );

            default:
                return null;
        }
    };

    const loadDomainData = () => {
        setSelected(domain?.type);
        if (domain?.domain_go_push_history) {
            loadDataScript(domain?.domain_go_push_history?.type);
            setBuildScript({
                type: domain?.domain_go_push_history?.type,
                script: domain?.domain_go_push_history?.script_name,
                version: domain?.domain_go_push_history?.script_folder,
                preset: domain?.domain_go_push_history?.preset_id,
                additional_preset_id: domain?.domain_go_push_history?.additional_preset_id,
                source_id: domain?.domain_go_push_history?.source_id,
            });
        }

        if (domain?.domain_data?.thank_you_page) {
            setBuildTYP({
                full_valid_number_code_conversion_ads: domain?.domain_data?.thank_you_page?.fullValidNumberCodeConversionAds,
                full_visit_code_conversion_ads: domain?.domain_data?.thank_you_page?.fullVisitCodeConversionAds,
                short_code_conversion_ads: domain?.domain_data?.thank_you_page?.shortCodeConversionAds,
                geo: domain?.domain_data?.thank_you_page?.geo,
                custom_code: domain?.domain_data?.thank_you_page?.customCode,
                ga_code: domain?.domain_data?.thank_you_page?.gaCode,
                locale: domain?.domain_data?.thank_you_page?.locale,
            });
        }

        if (domain?.domain_data?.success_page) {
            setBuildTY({
                full_valid_number_code_conversion_ads: domain?.domain_data?.success_page?.fullValidNumberCodeConversionAds,
                full_visit_code_conversion_ads: domain?.domain_data?.success_page?.fullVisitCodeConversionAds,
                short_code_conversion_ads: domain?.domain_data?.success_page?.shortCodeConversionAds,
                geo: domain?.domain_data?.success_page?.geo,
                custom_code: domain?.domain_data?.success_page?.customCode,
                ga_code: domain?.domain_data?.success_page?.gaCode,
                locale: domain?.domain_data?.success_page?.locale,
            });
        }
    };

    const createRK = async () => {
        if (edit) {
            const optionsPaladium = {
                campaign_name: domain.name,
            };
            if (buildRK.exclude_country.length !== 0) {
                optionsPaladium.exclude_country = buildRK.exclude_country;
            }

            if (buildRK.include_country.length !== 0) {
                optionsPaladium.include_country = buildRK.include_country;
            }

            if (buildRK.language.length !== 0) {
                optionsPaladium.language = buildRK.language;
            }

            await dispatch(updateRk(domain.id, optionsPaladium));

            return;
        }

        const optionsPaladium = {
            campaign_name: domain.name,
        };
        const optionsBinom = {
            user_id: userId,
            binom_key: selectedBinom,
            name: domain.name,
        };

        if (buildRK.exclude_country.length !== 0) {
            optionsPaladium.exclude_country = buildRK.exclude_country;
        }

        if (buildRK.include_country.length !== 0) {
            optionsPaladium.include_country = buildRK.include_country;
        }

        if (buildRK.language.length !== 0) {
            optionsPaladium.language = buildRK.language;
        }

        await dispatch(paladiumCreate(domain.id, optionsPaladium));

        await dispatch(binomCreate(domain.id, optionsBinom));
    };

    const createScript = async () => {
        const idDomain = domain?.id;
        await dispatch(connectScript(buildScript, idDomain));
    };

    const createTYP = async (push) => {
        if (push) {
            await dispatch(createThankYouPage(buildTYP, domain.id));
        }
        await dispatch(createSuccessPage(buildTY, domain.id));
    };

    const handleChangeDomain = async () => {
        const options = {
            topic_id: domain['topic'].id,
            name: domain.name,
            registrar: domain.registrar,
            domain_ip: domain.domain_ip,
            domain_ip_alt: domain.domain_ip_alt,
            server_id: domain.server.id,
            status: domain.status,
            domain_data: domain.domain_data ?? [],
            user_id: domain.user_id,
            type: selected,
        };

        await dispatch(changeStatusDomainWarm(options, domain.id));
    };

    const handleClickButtonSetServer = async () => {
        const domain = domain.id;
        const whitePage = domain.domain_data?.white_page?.white_id;
        await dispatch(setWhitePageDomain(domain, whitePage));
        loadDomain();
    };

    const loadDataScript = async (type) => {
        const script = await dispatch(getScripts(type));
        if (script) {
            const optionScript = script.map((item) => ({ label: item, value: item }));
            setScripts(optionScript);
        }
        const preset = await dispatch(getPresets(type));
        if (preset) {
            const optionPreset = preset.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setPresets(optionPreset);
        }
    };

    const handleClickButtonSave = async () => {
        if (isLoading) return;

        try {
            setIsLoading(true);
            switch (selected) {
                case 'Витрина':
                    await handleChangeDomain();
                    await createTYP();
                    await createRK();
                    loadDomain();
                    closeModal();
                    defaultState();
                    await handleClickButtonSetServer();
                    break;

                case 'Витрина + Пуш':
                    await handleChangeDomain();
                    await createTYP();
                    await createScript();
                    await createTYP(true);
                    await createRK();
                    loadDomain();
                    closeModal();
                    defaultState();
                    await handleClickButtonSetServer();
                    break;
                case 'Прямой Пуш':
                    await handleChangeDomain();
                    await createTYP(true);
                    await createScript();
                    await createRK();
                    loadDomain();
                    closeModal();
                    defaultState();
                    await handleClickButtonSetServer();
                    break;
                case 'Прямое ДАО':
                    await handleChangeDomain();
                    await createTYP();
                    await createScript();
                    await createRK();
                    loadDomain();
                    closeModal();
                    defaultState();
                    await handleClickButtonSetServer();
                    break;
                default:
                    break;
            }

            setIsLoading(false);
            closeModal();
            loadDomain();
        } catch (e) {
            setIsLoading(false);
        }
    };

    const isDisabledButton = () => {
        if (selected === 'Витрина') {
            if (Object.keys(buildTY).length === 0) return true;
            if (Object.keys(buildRK).length === 0) return true;
            return false;
        }

        if (selected === 'Витрина + Пуш') {
            if (Object.keys(buildTY).length === 0) return true;
            if (Object.keys(buildRK).length === 0) return true;
            if (Object.keys(buildTYP).length === 0) return true;
            if (Object.keys(buildScript).length <= 3) return true;
            return false;
        }

        if (selected === 'Прямой Пуш') {
            if (Object.keys(buildRK).length === 0) return true;
            if (Object.keys(buildTYP).length === 0) return true;
            if (Object.keys(buildScript).length <= 3) return true;
            return false;
        }

        if (selected === 'Прямой Пуш') {
            if (Object.keys(buildRK).length === 0) return true;
            if (Object.keys(buildTY).length === 0) return true;
            if (Object.keys(buildScript).length <= 3) return true;
            return false;
        }

        return false;
    };
    return (
        <Modal show={show} onHide={closeModal} className="modal-settings">
            <Modal.Header closeButton>
                <Modal.Title>{!edit ? 'Создать RK' : 'Изменить RK'}</Modal.Title>
            </Modal.Header>
            <div className="setting-wrapper">
                <Row>
                    <Col xs={12}>
                        <SelectSearch
                            onChange={handleChangeSelect}
                            options={optionsType}
                            itemLabel={'label'}
                            itemValue={'value'}
                            default={selected}
                        />
                    </Col>
                </Row>
                {renderTabs()}
                <Row>
                    {activeTab === 1 && <ModalThankYouPage build={buildTY} setBuild={setBuildTY} domain={domain} />}
                    {activeTab === 2 && (
                        <ModalScripts
                            build={buildScript}
                            setBuild={setBuildScript}
                            domain={domain}
                            scripts={scripts}
                            setScripts={setScripts}
                            versions={versions}
                            setVersions={setVersions}
                            presets={presets}
                            setPresets={setPresets}
                        />
                    )}
                    {activeTab === 3 && <ModalThankYouPage push build={buildTYP} setBuild={setBuildTYP} domain={domain} />}
                    {activeTab === 4 && (
                        <ModalCreateRK
                            selectedBinom={selectedBinom}
                            setSelectedBinom={setSelectedBinom}
                            domain={domain}
                            edit={edit}
                            userId={userId}
                            setBuild={setBuildRK}
                            build={buildRK}
                        />
                    )}
                </Row>

                {activeTab === 4 && (
                    <div className="CreateDomain-buttons">
                        <Button
                            style={{ background: 'silver' }}
                            className="wrapper-button-added-path-button"
                            variant="secondary"
                            onClick={closeModal}
                        >
                            Отмена
                        </Button>
                        <Button
                            className="wrapper-button-added-path-button"
                            variant="primary"
                            disabled={isDisabledButton()}
                            onClick={handleClickButtonSave}
                        >
                            {isLoading ? <Spinner size="sm" animation="border" /> : 'Сохранить'}
                        </Button>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default ModalSettingRK;
